<template>
  <v-row class="inner-content">
    <v-col>

      <v-card>
        <v-card-title>
          <span>Account</span>

          <v-spacer></v-spacer>

          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>

        </v-card-title>

        <KeywordRelocationTable :pAccountID="selectedAccountID"></KeywordRelocationTable>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KeywordRelocationReviewData',

  data () {
    return {
      lsSelectedAccount: 'keywordRelocation_selectedAccount',
      selectedAccountID: 0,
      updating: false,
      processing: false,
    }
  },

  computed: {
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    KeywordRelocationTable: () => import('@/components/tables/KeywordRelocationTable.vue')
  },

  methods: {
    accountChanged: function () {
      if (this.selectedAccountID) {
        localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))
      }
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
    }
},
}
</script>
